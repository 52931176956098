<template>
  <StaticPagePug>
    <div class="form-wrapper">
        <VerificationComponent
          ref="verification"
          :dialogVisible.sync="verificationVisibility"
          :usedAuthTypes="usedAuthTypes"
          @updateState="updateState"
          action="login"
        ></VerificationComponent>
      </div>
      </StaticPagePug>
</template>

<script>
import SocialMedia from '@/components/SocialMedia';
import ChangeEmailComponent from '@/components/security/ChangeEmailComponent.vue';
import VerificationDialog from '@/components/security/VerificationDialog.vue';
import VerificationComponent from '@/components/security/VerificationComponent.vue';
import helper from '@/util/signinHelper.js';
import StaticPagePug from '@/components/template/staticPage/StaticPagePug';

export default {
  name: 'VerifyAuthentication',
  components: { VerificationComponent, SocialMedia, StaticPagePug },
  mixins: [],
  data() {
    return {
      usedComponent: null,
      verificationVisibility: false,
      usedAuthTypes: []
    };
  },
  computed: {},
  mounted() {
    this.usedAuthTypes = this.$store.state.common.checkedAuthStatus.filter(
      item => item.path == '/verifyAuthentication' && !item.authenticated
    );
    console.log(this.usedAuthTypes);
    if (this.usedAuthTypes.length > 0) {
      this.verificationVisibility = true;
    } else {
      this.updateState(null);
    }

    setTimeout(function () {
      $('.default-from-box').css('animation', 'none').css('display', 'none');
      $('.from-box').css('display', 'block');
    }, 1);
  },
  watch: {},
  methods: {
    async updateState(val) {
      let lackAuthValues = this.$store.state.common.checkedAuthStatus;
      if (val) {
        lackAuthValues = this.$store.state.common.checkedAuthStatus.map(item => {
          return {
            ...item,
            namespace: 'CP_LOGIN',
            authenticated: item.type == val.type ? true : item?.authenticated
          };
        });
      }

      if (lackAuthValues.every(item => item.authenticated)) {
        this.$router.push('/login');
        this.$store.state.common.loginCredentials.method == 'register'
          ? helper.register(this.$store.state.common.loginCredentials?.request?.a)
          : this.$store.state.common.loginCredentials.method == 'signInMobile'
          ? helper.signInMobile(
              this.$store.state.common.loginCredentials?.request?.country_code,
              this.$store.state.common.loginCredentials?.request?.mobile_login,
              this.$store.state.common.loginCredentials?.request?.password_login
            )
          : helper.signIn(
              this.$store.state.common.loginCredentials?.request?.userName_login,
              this.$store.state.common.loginCredentials?.request?.password_login
            );
      } else {
        //not using path as filter as it might be having same path for totp and email
        const tempObj = lackAuthValues.find(item => !item.authenticated);

        if (tempObj && tempObj?.path != this.$router.currentRoute.path) {
          this.$router.push(tempObj?.path);
        }
      }
      this.$store.commit('common/setCheckedAuthStatus', lackAuthValues);
    }
  }
};
</script>

<style lang="scss" scoped>
.form-wrapper {
  width: 450px !important;
  padding: 40px 40px;
  border-radius: 16px;
  background-color: white;
  max-width: 90%;
}
</style>
